import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Quote from '../../../images/svgs/quote.svg'

const Testimonials: React.FC = ({ showExtra }) => (
  <StaticQuery
    query={graphql`
      query {
        oneImage: file(relativePath: { eq: "food/lentil-bolo.jpg" }) {
          size
          childImageSharp {
            fluid {
              src
              sizes
            }
          }
        }
        twoImage: file(relativePath: { eq: "food/sweet-potato-lentil.jpg" }) {
          size
          childImageSharp {
            fluid {
              src
              sizes
            }
          }
        }
        threeImage: file(relativePath: { eq: "food/harissa-tofu.jpg" }) {
          size
          childImageSharp {
            fluid {
              src
              sizes
            }
          }
        }
        fourImage: file(relativePath: { eq: "food/cashew-pasta.jpg" }) {
          size
          childImageSharp {
            fluid {
              src
              sizes
            }
          }
        }
      }
    `}
    render={data => (
      <section>
        <div className="container py-3 my-5">
          <div className="row">
            <div className="col-12 mb-0 mb-md-5">
              <h2 className="font-weight-bold mx-auto text-center">What our customers say</h2>
            </div>
            {showExtra && <div className="col-12 col-md-10 smaller text-black-50 mb-4">
              Before ELM we were eating a trash diet. We tried meal planning, it was too much work. We tried eating meal-kits, it was too restrictive. ELM is so helpful without constrictive, it's helped us eat in a way that's easy, healthy and delicious.
            </div>
            }
            <div className="col-6 col-md-3 text-center">
              <Link to="/recipes/lentil-bolognese">
                <img className="img-fluid bowl mb-4 mt-5 mt-md-0" alt="Lentil Bolo" src={data.oneImage.childImageSharp.fluid.src} />
              </Link>
              <h4 className="font-weight-bold">Norma K.</h4>
              <p>
                <Quote className="quote" />
              </p>
              <p className="smaller font-italic text-black-50">
                I never thought I'd crave plant based food, but i do. it's wild how tasty these meals are.
              </p>
            </div>
            <div className="col-6 col-md-3 text-center">
              <Link to="/recipes/harissa-tofu-toast">
                <img className="img-fluid bowl mb-4 mt-5 mt-md-0" alt="Tofu Toast" src={data.threeImage.childImageSharp.fluid.src} />
              </Link>
              <h4 className="font-weight-bold">Josh F.</h4>
              <p>
                <Quote className="quote" />
              </p>
              <p className="smaller font-italic text-black-50">
                I think this is really effing great.
              </p>
            </div>
            <div className="col-6 col-md-3 text-center">
              <Link to="/recipes/roasted-sweet-potatoes-with-tahini-dill-sauce">
                <img className="img-fluid bowl mb-4 mt-5 mt-md-0" alt="Dill Sweet Potato" src={data.twoImage.childImageSharp.fluid.src} />
              </Link>
              <h4 className="font-weight-bold">Diana L.</h4>
              <p>
                <Quote className="quote" />
              </p>
              <p className="smaller font-italic text-black-50">
                These recipes are easy and yummy and really helping us not eat only frozen burritos.
              </p>
            </div>
            <div className="col-6 col-md-3 text-center">
              <Link to="/recipes/creamy-cashew-pasta">
                <img className="img-fluid bowl mb-4 mt-5 mt-md-0" alt="Tofu Toast" src={data.fourImage.childImageSharp.fluid.src} />
              </Link>
              <h4 className="font-weight-bold">Nadia R.</h4>
              <p>
                <Quote className="quote" />
              </p>
              <p className="smaller font-italic text-black-50">
                Eating more plant-based has been a lot easier thanks to ELM. We’ve enjoyed the tasty and satisfying meals, and have not missed beef or chicken at all.
              </p>
            </div>
          </div>
        </div>
      </section >
    )}
  />
)


export default Testimonials
